import { Component, Input, OnInit } from '@angular/core';
import { IExpandableObject } from '@model/expandable-object';
import { OfficeFeeDynamicControls } from '@model/form-controls/office-fee.form-controls';
import { OfficeFeeDynamicControlsPartial } from '@model/partials/office-fee.form-controls';
import { IOfficeFee } from '@model/interfaces/office-fee';
import { MetaItem } from '@mt-ng2/base-service';
import { MetaItemManagedListComponent } from '@mt-ng2/managed-list-control';
import { ModalService } from '@mt-ng2/modal-module';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { ISelectionChangedEvent, TypeAheadComponent } from '@mt-ng2/type-ahead-control';
import { OfficeService } from 'admin-portal/offices/services/office.service';
import { Observable } from 'rxjs';
import { OfficeFeeService } from '../services/office-fee.service';

@Component({
    selector: 'officefee-meta-item-managed-list',
    template: `
        <mt-meta-item-managed-list
            *ngIf="ready"
            [dynamicForm]="officeFeeDynamicForm"
            componentTitle="Office Fee Overrides"
            [sortable]="false"
            [duplicateCheck]="true"
            [duplicateCheckFields] = "duplicatecheckfields"
            [canAdd]="canEdit"
            [canRemove]="canEdit"
            [canEdit]="canEdit"
            [fields]="fields"
            [service]="officeFeeService"
        >
        </mt-meta-item-managed-list>
    `,

})

export class OfficefeeMetaItemManagedListComponent implements OnInit {
    @Input() canEdit = false;
    fields = ['Fee', 'OfficeId'];
    duplicatecheckfields = ['OfficeId'];
    ready = false;

    officeFeeDynamicForm: IExpandableObject;

    constructor(
        public officeService: OfficeService,
        public officeFeeService: OfficeFeeService) {}

    ngOnInit(): void {
        this.officeService.getAll().subscribe((offices) => {
            this.officeFeeDynamicForm = new OfficeFeeDynamicControlsPartial(null, {
                offices: offices,
            }).Form;
            this.ready = true;
        });
    }
}
