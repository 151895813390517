import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StaticMetaItemService } from '@mt-ng2/base-service';
import { IRoom } from '@model/interfaces/room';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class RoomService extends StaticMetaItemService<IRoom> {
    url: string;

    constructor(public http: HttpClient) {
        super('RoomService', 'Room', 'RoomIds', '/estimator/rooms', http);
        this.url = '/estimator/rooms';
    }

    addRoom(room: IRoom): Observable<IRoom> {
        return this.http.post<IRoom>(this.url, room);
    }
}
