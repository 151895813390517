import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';

import { IExpandableObject } from '../expandable-object';
import { IOfficeFee } from '../interfaces/office-fee';
import { IOffice } from '../interfaces/office';

export interface IOfficeFeeDynamicControlsParameters {
    formGroup?: string;
    offices?: IOffice[];
}

export class OfficeFeeDynamicControls {

    formGroup: string;
    offices: IOffice[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private officefee?: IOfficeFee, additionalParameters?: IOfficeFeeDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'OfficeFee';
        this.offices = additionalParameters && additionalParameters.offices || undefined;

        this.Form = {
            Fee: new DynamicField({
                formGroup: this.formGroup,
                label: 'Fee',
                name: 'Fee',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 4,
                }),
                validation: [  ],
                validators: {  },
                value: this.officefee && this.officefee.Fee || 0,
            }),
            Name: new DynamicField({
                formGroup: this.formGroup,
                label: 'Name',
                name: 'Name',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.officefee && this.officefee.hasOwnProperty('Name') && this.officefee.Name !== null ? this.officefee.Name.toString() : '',
            }),
            OfficeId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Office',
                name: 'OfficeId',
                options: this.offices,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.officefee && this.officefee.OfficeId || null,
            }),
        };

        this.View = {
            Fee: new DynamicLabel({
                label: 'Fee',
                value: this.officefee && this.officefee.Fee || 0,
                type: new DynamicFieldType({
                   fieldType: DynamicFieldTypes.Numeric,
                   inputType: null,
                   scale: 4,
               }),
          }),
            Name: new DynamicLabel({
              label: 'Name',
              value:  this.officefee && this.officefee.hasOwnProperty('Name') && this.officefee.Name !== null ? this.officefee.Name.toString() : '',
              type:  new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
          }),
            OfficeId: new DynamicLabel({
                label:  'Office',
                value: getMetaItemValue(this.offices, this.officefee && this.officefee.hasOwnProperty('OfficeId') && this.officefee.OfficeId !== null ? this.officefee.OfficeId : null),
                type: new DynamicFieldType({
                   fieldType: DynamicFieldTypes.Select,
                   inputType: null,
                   scale: null,
               }),
           }),
        };

    }
}
