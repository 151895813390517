import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IPart } from '@model/interfaces/part';
import { IProposalAccessory } from '@model/interfaces/proposal-accessory';
import { HeatPumpFormStateService } from 'admin-portal/estimator/services/heat-pump-form-state.service';
import { ProposalAccessoryService } from 'admin-portal/estimator/services/proposal-accessory.service';
import { PartService } from 'admin-portal/parts/services/part.service';
import { Subscription } from 'rxjs';
import { CustomerProposalInterfaceStateService } from 'admin-portal/estimator/services/customer-proposal-interface-state.service';
import { HeatPumpsComponent } from '../heat-pumps.component';
import { LocalStorageService } from '@common/services/local-storage.service';
import { ModalService } from '@mt-ng2/modal-module';
import { HeatPumpService } from 'admin-portal/estimator/services/heat-pump.service';
import { CustomerProposalService } from 'admin-portal/estimator/services/customer-proposal.service';

@Component({
    selector: 'accessories',
    styles: [
        `
            .table {
                color: black !important;
            }
            .btn-fab-md {
                float: right;
            }
            .th-numeric-input {
                width: 75px !important;
            }
        `,
    ],
    templateUrl: 'accessories.component.html',
})
export class AccessoriesComponent extends HeatPumpsComponent implements OnInit {
    @Input() proposalIsApproved: boolean;
    selectedAccessories: IProposalAccessory[] = [];
    manufacturerId: number;
    parts: IPart[] = [];
    subscriptions: Subscription = new Subscription();
    customerProposalId: number;
    selectedAccessoriesOnInit: IProposalAccessory[] = [];

    constructor(
        private partService: PartService,
        private proposalStateService: CustomerProposalInterfaceStateService,
        private heatPumpStateService: HeatPumpFormStateService,
        private route: ActivatedRoute,
        private localStorageService: LocalStorageService,
        private modalService: ModalService,
        private heatPumpService: HeatPumpService,
        private proposalAccessoryService: ProposalAccessoryService,
        private customerProposalService: CustomerProposalService,
    ) {
        super(proposalStateService, heatPumpStateService, localStorageService, modalService, heatPumpService);
    }

    ngOnInit(): void {
        this.customerProposalId = this.getIdFromRoute(this.route, 'customerProposalId');
        if (this.customerProposalId) {
            this.getAccessoriesByProposalId(this.customerProposalId);
        }
        this.initializeSubscriptions();
    }

    getAccessoriesByProposalId(proposalId: number): void {
        this.subscriptions.add(
            this.proposalStateService.heatPumpConfigurationStatus$.subscribe((value) => {
                this.heatPumpConfigurationStatus = value;
            }),
        );
        this.proposalAccessoryService.getByProposalId(proposalId).subscribe((accessories) => {
            if (accessories.length > 0) {
                this.mapAccessories(accessories);

                this.selectedAccessoriesOnInit = JSON.parse(JSON.stringify(accessories));
            }
        });
    }

    private mapAccessories(accessories: IProposalAccessory[]): void {
        this.selectedAccessories = accessories.map((a) => {
            return {
                Distance: a.Distance ?? 10,
                Id: a.Id,
                IsAutoIncluded: a.IsAutoIncluded,
                PartId: a.PartId,
                ProposalId: a.ProposalId,
                Quantity: a.Quantity,
            };
        });
        this.updateHeatPumpStateService();
    }

    private initializeSubscriptions(): void {
        this.subscriptions.add(
            this.heatPumpStateService.manufacturerSelection$.subscribe((value) => {
                if (value && value.ManufacturerId) {
                    if (this.manufacturerId !== value.ManufacturerId) {
                        this.manufacturerId = value.ManufacturerId;
                        this.partService
                            .getAvailableAccessoriesByManufacturerAndProposal(value.ManufacturerId, this.customerProposalId)
                            .subscribe((parts) => {
                                this.parts = parts.filter((p) => {
                                    return p.Selectable;
                                });
                                this.addPartsToAccessories();
                                this.updateAmountOfBranchBoxes();
                                this.updateHeatPumpConfigurationStatus();
                                this.updateHeatPumpStateService();
                            });
                    }
                }
                if (value && value.RequiresChange) {
                    this.selectedAccessories = [];
                }
            }),
        );
    }

    addAccessory(): void {
        this.selectedAccessories.push({
            Distance: 10,
            Id: 0,
            IsAutoIncluded: false,
            PartId: null,
            ProposalId: this.customerProposalId,
            Quantity: 1,
        });
    }

    removeAccessory(index: number): void {
        this.selectedAccessories.splice(index, 1);
        this.updateHeatPumpStateService();
    }

    onPartUpdate(partId: string, index: number): void {
        const selectedPart = this.parts.find((p) => p.Id === +partId);
        this.selectedAccessories[index].PartId = selectedPart.Id;
        this.selectedAccessories[index].Part = selectedPart;
        this.updateAmountOfBranchBoxes();
        this.updateHeatPumpConfigurationStatus();
        this.updateHeatPumpStateService();

        this.checkIfFormValuesChanged();
    }

    checkIfFormValuesChanged(): void {
        const formIsDirty = this.checkIfFormIsDirty();
        this.customerProposalService.setFormIsDirty(formIsDirty);
    }

    checkIfFormIsDirty(): boolean {
        if (this.selectedAccessoriesOnInit.length !== this.selectedAccessories.length) {
            return true;
        }

        for (let i = 0; i < this.selectedAccessories.length; i++) {
            const currentAccessory = this.selectedAccessories[i];
            const proposedAccessory = this.selectedAccessoriesOnInit[i];

            if (this.checkAccessoryForDifferences(proposedAccessory, currentAccessory)) {
                return true;
            }
        }

        return false;
    }

    private checkAccessoryForDifferences(proposedAccessory: IProposalAccessory, currentAccessory: IProposalAccessory): boolean {
        for (const key in currentAccessory) {
            if (key !== 'Part') {
                const proposedValue = proposedAccessory[key];
                const currentValue = currentAccessory[key];
                if (proposedValue !== currentValue) {
                    return true;
                }
            }
        }
        return false;
    }

    updateHeatPumpStateService(): void {
        this.updateAmountOfBranchBoxes();
        this.updateHeatPumpConfigurationStatus();
        this.heatPumpStateService.accessories$.next(this.selectedAccessories);
        this.checkIfFormValuesChanged();
    }

    getIdFromRoute(route: ActivatedRoute, param: string): number {
        const id = route.snapshot.paramMap.get(param);
        const parsedInt = parseInt(id, 10);
        return isNaN(parsedInt) ? null : parseInt(id, 10);
    }

    private updateAmountOfBranchBoxes(): void {
        if (!this.selectedAccessories) {
            return;
        }
        const branchBoxes = this.selectedAccessories.filter((item) => item.Part?.IsBranchBox).reduce((sum, current) => sum + current.Quantity, 0);
        this.heatPumpConfigurationStatus.BranchBoxes = branchBoxes;
    }

    private addPartsToAccessories(): void {
        this.selectedAccessories.forEach((a) => {
            const part = this.parts.find((p) => p.Id === a.PartId);
            if (!part) {
                return;
            }
            a.Part = part;
        });
    }
}
