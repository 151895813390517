import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { NumberFormatDisplayFunction, NumericControlTypes } from '@mt-ng2/numeric-control';
import { IOfficeFee } from '../interfaces/office-fee';
import { OfficeFeeDynamicControls, IOfficeFeeDynamicControlsParameters } from '../form-controls/office-fee.form-controls';
import { getMetaItemValue } from '@mt-ng2/common-functions';

export function allowZeroConversionFunction(value: number, type: NumericControlTypes, scale: number): number {
    return value ? value / 100 : 0;
}

export class OfficeFeeDynamicControlsPartial extends OfficeFeeDynamicControls {
    constructor(officeFee?: IOfficeFee, additionalParamters?: IOfficeFeeDynamicControlsParameters) {
        super(officeFee, additionalParamters);

        this.Form = {
            Fee: new DynamicField({
                formGroup: this.formGroup,
                label: 'Fee',
                name: 'Fee',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Percentage,
                    numericFunctions: {
                        convertFromDisplayToForm: allowZeroConversionFunction,
                    },
                    scale: 2,
                }),
                validation: [Validators.required],
                validators: { 'required': true },
                value: officeFee && officeFee.Fee || null,
            }),
            OfficeId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Office',
                name: 'OfficeId',
                options: this.offices,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: officeFee && officeFee.OfficeId || null,
            }),
        };

        this.View = {
            Fee: new DynamicLabel({
                label: 'Fee',
                value: officeFee && officeFee.Fee || 0,
                type: new DynamicFieldType({
                   fieldType: DynamicFieldTypes.Numeric,
                   inputType: null,
                   scale: 2,
               }),
          }),
          OfficeId: new DynamicLabel({
            label:  'Office',
            value: getMetaItemValue(this.offices, officeFee && officeFee.hasOwnProperty('OfficeId') && officeFee.OfficeId !== null ? officeFee.OfficeId : null),
            type: new DynamicFieldType({
               fieldType: DynamicFieldTypes.Select,
               inputType: null,
               scale: null,
           }),
       }),
        };

    }
}
