import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from '@mt-ng2/base-service';
import { ICustomerProposal } from '@model/interfaces/customer-proposal';
import { Observable, Subject, catchError, throwError } from 'rxjs';
import { CustomerProposalStatuses } from '@model/CustomerProposalStatuses';
import { ICustomerProposalPricingPageDTO } from '@model/interfaces/custom/customer-proposal-pricing-page-dto';
import { ICustomerProposalAccessCode } from '@model/interfaces/customer-proposal-access-code';
import { IUser } from '@model/interfaces/user';
import { UserRoles } from '@model/UserRoles';

export const emptyCustomerProposal: ICustomerProposal = {
    AltPhone: null,
    CustomerId: null,
    CustomerJobDescription: null,
    CustomerLocationId: null,
    CustomerName: null,
    DateCreated: null,
    DateOfProposal: null,
    EmailAddress: null,
    Id: 0,
    IncludeWarranty: true,
    InternalNotes: null,
    JobLocationId: null,
    Name: null,
    OfficeId: null,
    PrimaryPhone: null,
    ProposalJobDetails: null,
    SalesPersonId: null,
    StatusId: 1,
    UsesFinancing: false,
    UniqueIdentifier: null,
    Archived: false,
};

@Injectable({
    providedIn: 'root',
})
export class CustomerProposalService extends BaseService<ICustomerProposal> {
    private dataTransferSubject = new Subject<boolean>();
    dataTransferObservable = this.dataTransferSubject.asObservable();

    constructor(public http: HttpClient) {
        super('/customer-proposals', http);
    }

    proposalIsInProgress(customerProposal: ICustomerProposal): boolean {
        return customerProposal.StatusId === CustomerProposalStatuses.InProgress;
    }

    proposalIsInApproved(customerProposal: ICustomerProposal): boolean {
        return customerProposal.StatusId === CustomerProposalStatuses.Approved;
    }

    isCurrentUserAdminAndProposalQuotedOrAwaiting(currentUser: IUser,customerProposal: ICustomerProposal): boolean {
        return currentUser.AuthUser?.RoleId == UserRoles.Admin && (customerProposal?.StatusId === CustomerProposalStatuses.Quoted || customerProposal?.StatusId === CustomerProposalStatuses.AwaitingSalesOrder);
    }

    getEmptyCustomerProposal(): ICustomerProposal {
        return { ...emptyCustomerProposal };
    }

    saveDigitalSignature(id: number, signatureURI: string): Observable<string | HttpErrorResponse> {
        const byteString = atob(signatureURI.split(',')[1]);
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        const bb = new Blob([ab]);
        const formData: FormData = new FormData();
        formData.append('file', bb);
        return this.http.post<string>(`/customer-proposals/${id}/signature`, formData).pipe(
            catchError((error: HttpErrorResponse) => {
                return throwError(error);
            })
        );
    }

    completeCustomerProposal(id: number, proposalPricingId: number): Observable<number> {
        return this.http.get<number>(`/customer-proposals/${id}/pricing/${proposalPricingId}/complete`);
    }

    emailCustomerProposal(id: number, proposalPricingId: number): Observable<void | HttpErrorResponse> {
        return this.http.get<void>(`/customer-proposals/${id}/pricing/${proposalPricingId}/email`).pipe(
            catchError((error: HttpErrorResponse) => {
                return throwError(error);
            })
        );
    }

    copyCustomerProposal(id: number, name: string): Observable<number> {
        return this.http.post<number>(`/customer-proposals/${id}/copy`, { Name: name });
    }

    getByUniqueIdentifier(uniqueIdentifier: string): Observable<ICustomerProposalPricingPageDTO> {
        return this.http.post<ICustomerProposalPricingPageDTO>(`/customer-proposals/public`, { UniqueIdentifier: uniqueIdentifier });
    }

    retryCreateStrivenSalesOrder(id: number): Observable<number> {
        return this.http.get<number>(`/customer-proposals/${id}/retry`);
    }

    getAccessCode(id: number, accessCode: string): Observable<ICustomerProposalAccessCode> {
        return this.http.post<ICustomerProposalAccessCode>(`/customer-proposals/${id}/access-code`, { AccessCode: accessCode });
    }

    getCustomerProposalPdf(id: number): Observable<string> {
        return this.http.get<string>(`/customer-proposals/${id}/pdf`);
    }

    getCustomerProposalPricingPageDTO(id: number): Observable<ICustomerProposalPricingPageDTO> {
        return this.http.get<ICustomerProposalPricingPageDTO>(`/customer-proposals/${id}/pricing-page`);
    }

    setFormIsDirty(formIsDirty: boolean): void {
        this.dataTransferSubject.next(formIsDirty);
    }

    moveCustomerProposals(from: number, to: number): Observable<void> {
        return this.http.put<void>(`/customer-proposals/move`, {
            MoveFromCustomerId: from,
            MoveToCustomerId: to
        });
    }

    deleteCustomerProposal(proposalId: number): Observable<object> {
        return this.http.delete(`/customer-proposals/delete/${proposalId}`, {
            responseType: 'text' as 'json',
        });
    }
}
