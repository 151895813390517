import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';
import { IMetaItem } from '../interfaces/base';

import { IExpandableObject } from '../expandable-object';
import { IProposalJobDetail } from '../interfaces/proposal-job-detail';
import { IFoundationType } from '../interfaces/foundation-type';
import { IJobType } from '../interfaces/job-type';
import { ILiftType } from '../interfaces/lift-type';
import { ILineHideColor } from '../interfaces/line-hide-color';
import { IOduElevation } from '../interfaces/odu-elevation';
import { ICustomerProposal } from '../interfaces/customer-proposal';
import { IWallConstructionType } from '../interfaces/wall-construction-type';

export interface IProposalJobDetailDynamicControlsParameters {
    formGroup?: string;
    jobTypes?: IJobType[];
    lineHideColors?: ILineHideColor[];
    foundationTypes?: IFoundationType[];
    wallConstructionTypes?: IWallConstructionType[];
    liftTypes?: ILiftType[];
    oduElevations?: IOduElevation[];
    proposals?: ICustomerProposal[];
}

export class ProposalJobDetailDynamicControls {

    formGroup: string;
    jobTypes?: IJobType[];
    lineHideColors?: ILineHideColor[];
    foundationTypes?: IFoundationType[];
    wallConstructionTypes?: IWallConstructionType[];
    liftTypes?: ILiftType[];
    oduElevations?: IOduElevation[];
    proposals?: ICustomerProposal[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private proposaljobdetail?: IProposalJobDetail, additionalParameters?: IProposalJobDetailDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'ProposalJobDetail';
        this.jobTypes = additionalParameters && additionalParameters.jobTypes || undefined;
        this.lineHideColors = additionalParameters && additionalParameters.lineHideColors || undefined;
        this.foundationTypes = additionalParameters && additionalParameters.foundationTypes || undefined;
        this.wallConstructionTypes = additionalParameters && additionalParameters.wallConstructionTypes || undefined;
        this.liftTypes = additionalParameters && additionalParameters.liftTypes || undefined;
        this.oduElevations = additionalParameters && additionalParameters.oduElevations || undefined;
        this.proposals = additionalParameters && additionalParameters.proposals || undefined;

        this.Form = {
            AdditionalCharges: new DynamicField({
                formGroup: this.formGroup,
                label: 'Additional Charges',
                name: 'AdditionalCharges',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.proposaljobdetail && this.proposaljobdetail.hasOwnProperty('AdditionalCharges') && this.proposaljobdetail.AdditionalCharges != null ? this.proposaljobdetail.AdditionalCharges : false,
            }),
            FoundationTypeId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Foundation Type',
                name: 'FoundationTypeId',
                options: this.foundationTypes,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.proposaljobdetail && this.proposaljobdetail.FoundationTypeId || null,
            }),
            JobDifficulty: new DynamicField({
                formGroup: this.formGroup,
                label: 'Job Difficulty',
                name: 'JobDifficulty',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.proposaljobdetail && this.proposaljobdetail.hasOwnProperty('JobDifficulty') && this.proposaljobdetail.JobDifficulty != null ? this.proposaljobdetail.JobDifficulty.toString() : '',
            }),
            JobTypeId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Job Type',
                name: 'JobTypeId',
                options: this.jobTypes,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.proposaljobdetail && this.proposaljobdetail.JobTypeId || null,
            }),
            LiftTypeId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Lift Type',
                name: 'LiftTypeId',
                options: this.liftTypes,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.proposaljobdetail && this.proposaljobdetail.LiftTypeId || null,
            }),
            LineHideColorId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Line Hide Color',
                name: 'LineHideColorId',
                options: this.lineHideColors,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.proposaljobdetail && this.proposaljobdetail.LineHideColorId || null,
            }),
            NoOfInstallers: new DynamicField({
                formGroup: this.formGroup,
                label: 'No Of Installers',
                name: 'NoOfInstallers',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.proposaljobdetail && this.proposaljobdetail.NoOfInstallers || 0,
            }),
            OduElevationId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Odu Elevation',
                name: 'OduElevationId',
                options: this.oduElevations,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.proposaljobdetail && this.proposaljobdetail.OduElevationId || null,
            }),
            ProposalId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Proposal',
                name: 'ProposalId',
                options: this.proposals,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.proposaljobdetail && this.proposaljobdetail.ProposalId || null,
            }),
            ReferralDiscount: new DynamicField({
                formGroup: this.formGroup,
                label: 'Referral Discount',
                name: 'ReferralDiscount',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.proposaljobdetail && this.proposaljobdetail.hasOwnProperty('ReferralDiscount') && this.proposaljobdetail.ReferralDiscount != null ? this.proposaljobdetail.ReferralDiscount : false,
            }),
            RoughIn: new DynamicField({
                formGroup: this.formGroup,
                label: 'Rough In',
                name: 'RoughIn',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.proposaljobdetail && this.proposaljobdetail.hasOwnProperty('RoughIn') && this.proposaljobdetail.RoughIn != null ? this.proposaljobdetail.RoughIn : false,
            }),
            RoughInBoxes: new DynamicField({
                formGroup: this.formGroup,
                label: 'Rough In Boxes',
                name: 'RoughInBoxes',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.proposaljobdetail && this.proposaljobdetail.hasOwnProperty('RoughInBoxes') && this.proposaljobdetail.RoughInBoxes != null ? this.proposaljobdetail.RoughInBoxes : false,
            }),
            SnakeJob: new DynamicField({
                formGroup: this.formGroup,
                label: 'Snake Job',
                name: 'SnakeJob',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.proposaljobdetail && this.proposaljobdetail.hasOwnProperty('SnakeJob') && this.proposaljobdetail.SnakeJob != null ? this.proposaljobdetail.SnakeJob : false,
            }),
            WallConstructionTypeId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Wall Construction Type',
                name: 'WallConstructionTypeId',
                options: this.wallConstructionTypes,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.proposaljobdetail && this.proposaljobdetail.WallConstructionTypeId || null,
            }),
        };

        this.View = {
            AdditionalCharges: new DynamicLabel({
                label: 'Additional Charges',
                value: this.proposaljobdetail && this.proposaljobdetail.hasOwnProperty('AdditionalCharges') && this.proposaljobdetail.AdditionalCharges != null ? this.proposaljobdetail.AdditionalCharges : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            FoundationTypeId: new DynamicLabel({
                label: 'Foundation Type',
                value: getMetaItemValue(this.foundationTypes as unknown as IMetaItem[], this.proposaljobdetail && this.proposaljobdetail.hasOwnProperty('FoundationTypeId') ? this.proposaljobdetail.FoundationTypeId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            JobDifficulty: new DynamicLabel({
                label: 'Job Difficulty',
                value: this.proposaljobdetail && this.proposaljobdetail.hasOwnProperty('JobDifficulty') && this.proposaljobdetail.JobDifficulty != null ? this.proposaljobdetail.JobDifficulty.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            JobTypeId: new DynamicLabel({
                label: 'Job Type',
                value: getMetaItemValue(this.jobTypes as unknown as IMetaItem[], this.proposaljobdetail && this.proposaljobdetail.hasOwnProperty('JobTypeId') ? this.proposaljobdetail.JobTypeId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            LiftTypeId: new DynamicLabel({
                label: 'Lift Type',
                value: getMetaItemValue(this.liftTypes as unknown as IMetaItem[], this.proposaljobdetail && this.proposaljobdetail.hasOwnProperty('LiftTypeId') ? this.proposaljobdetail.LiftTypeId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            LineHideColorId: new DynamicLabel({
                label: 'Line Hide Color',
                value: getMetaItemValue(this.lineHideColors as unknown as IMetaItem[], this.proposaljobdetail && this.proposaljobdetail.hasOwnProperty('LineHideColorId') ? this.proposaljobdetail.LineHideColorId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            NoOfInstallers: new DynamicLabel({
                label: 'No Of Installers',
                value: this.proposaljobdetail && this.proposaljobdetail.NoOfInstallers || 0,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            }),
            OduElevationId: new DynamicLabel({
                label: 'Odu Elevation',
                value: getMetaItemValue(this.oduElevations as unknown as IMetaItem[], this.proposaljobdetail && this.proposaljobdetail.hasOwnProperty('OduElevationId') ? this.proposaljobdetail.OduElevationId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            ProposalId: new DynamicLabel({
                label: 'Proposal',
                value: getMetaItemValue(this.proposals as unknown as IMetaItem[], this.proposaljobdetail && this.proposaljobdetail.hasOwnProperty('ProposalId') ? this.proposaljobdetail.ProposalId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            ReferralDiscount: new DynamicLabel({
                label: 'Referral Discount',
                value: this.proposaljobdetail && this.proposaljobdetail.hasOwnProperty('ReferralDiscount') && this.proposaljobdetail.ReferralDiscount != null ? this.proposaljobdetail.ReferralDiscount : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            RoughIn: new DynamicLabel({
                label: 'Rough In',
                value: this.proposaljobdetail && this.proposaljobdetail.hasOwnProperty('RoughIn') && this.proposaljobdetail.RoughIn != null ? this.proposaljobdetail.RoughIn : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            RoughInBoxes: new DynamicLabel({
                label: 'Rough In Boxes',
                value: this.proposaljobdetail && this.proposaljobdetail.hasOwnProperty('RoughInBoxes') && this.proposaljobdetail.RoughInBoxes != null ? this.proposaljobdetail.RoughInBoxes : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            SnakeJob: new DynamicLabel({
                label: 'Snake Job',
                value: this.proposaljobdetail && this.proposaljobdetail.hasOwnProperty('SnakeJob') && this.proposaljobdetail.SnakeJob != null ? this.proposaljobdetail.SnakeJob : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            WallConstructionTypeId: new DynamicLabel({
                label: 'Wall Construction Type',
                value: getMetaItemValue(this.wallConstructionTypes as unknown as IMetaItem[], this.proposaljobdetail && this.proposaljobdetail.hasOwnProperty('WallConstructionTypeId') ? this.proposaljobdetail.WallConstructionTypeId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
        };

    }
}
