import { Component, inject, Inject, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LocalStorageService } from '@common/services/local-storage.service';
import { IIndoorHeatPumpLineItem } from '@model/interfaces/custom/indoor-heat-pump-line-item';
import { IIndoorHeatPumpLineItemUpdateEvent } from '@model/interfaces/custom/indoor-heat-pump-line-item-update-event';
import { IManufacturerSelection } from '@model/interfaces/custom/manufacturer-selection';
import { IPart } from '@model/interfaces/part';
import { IProposalIndoorHeatPump } from '@model/interfaces/proposal-indoor-heat-pump';
import { IRoom } from '@model/interfaces/room';
import { ModalService } from '@mt-ng2/modal-module';
import { CustomerProposalInterfaceStateService } from 'admin-portal/estimator/services/customer-proposal-interface-state.service';
import { HeatPumpFormStateService } from 'admin-portal/estimator/services/heat-pump-form-state.service';
import { ProposalIndoorHeatPumpService } from 'admin-portal/estimator/services/proposal-indoor-heat-pump.service';
import { RoomService } from '../../../estimator/services/room.service';
import { LineHideTypeService } from '../../../estimator/services/line-hide-type.service';
import { PartService } from '../../../parts/services/part.service';
import { HeatPumpsComponent } from '../heat-pumps.component';
import { CustomerProposalService } from 'admin-portal/estimator/services/customer-proposal.service';
import { HeatPumpService } from 'admin-portal/estimator/services/heat-pump.service';
import {  combineLatest, distinctUntilChanged, filter, forkJoin, switchMap } from 'rxjs';
import { ILineHideType } from '@model/interfaces/line-hide-type';
import { NotificationsService } from '@mt-ng2/notifications-module';

@Component({
    selector: 'indoor-heat-pumps',
    styles: [
        `
            .table {
                color: black !important;
            }
            .btn-fab-md {
                float: right;
            }
            .th-numeric-input {
                width: 75px !important;
            }
            .th-name-input{
                width: 170px !important;
            }
        `,
    ],
    templateUrl: 'indoor-heat-pumps.component.html',
})
export class IndoorHeatPumpsComponent extends HeatPumpsComponent implements OnInit {
    @Input() proposalIsApproved: boolean;
    @Input() proposalId: number;

    notificationsService: NotificationsService = inject(NotificationsService);

    selectedIndoorHeatPumps: IIndoorHeatPumpLineItem[] = [];
    rooms: IRoom[] = [];
    lineHideTypes: ILineHideType[] = [];
    manufacturerSelection: IManufacturerSelection;
    compatibleOutdoorUnitIds: number[] = [];
    selectedIndoorHeatPumpsOnInit: IIndoorHeatPumpLineItem[] = [];
    categoryId: number;

    constructor(
        private partService: PartService,
        private roomService: RoomService,
        private lineHideTypeService: LineHideTypeService,
        private proposalStateService: CustomerProposalInterfaceStateService,
        private proposalIndoorHeatPumpService: ProposalIndoorHeatPumpService,
        private heatPumpStateService: HeatPumpFormStateService,
        private route: ActivatedRoute,
        private localStorageService: LocalStorageService,
        private modalService: ModalService,
        private customerProposalService: CustomerProposalService,
        private heatPumpService: HeatPumpService,
    ) {
        super(proposalStateService, heatPumpStateService, localStorageService, modalService, heatPumpService);
    }

    ngOnInit(): void {
        forkJoin([this.roomService.getAll(), this.lineHideTypeService.getAll()]).subscribe((result) => {
            const [rooms, lineHideTypes] = result;
            this.rooms = rooms;
            this.lineHideTypes = lineHideTypes;
        });
        this.customerProposalId = this.getIdFromRoute(this.route, 'customerProposalId');
        if (this.customerProposalId) {
            this.getIndoorHeatPumpsByProposalId(this.customerProposalId);
        }
        this.initializeSubscriptions();
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    private getIndoorHeatPumpsByProposalId(proposalId: number): void {
        this.proposalIndoorHeatPumpService.getByProposalId(proposalId).subscribe((pumps) => {
            if (pumps.length > 0) {
                this.heatPumpStateService.categorySelection$.next(pumps[0].Part.CategoryId ?? 0);
                this.mapIndoorPumps(pumps);
            }
        });
    }

    private initializeSubscriptions(): void {
        this.subscriptions.add(
            this.proposalStateService.heatPumpConfigurationStatus$.subscribe((value) => {
                this.heatPumpConfigurationStatus = value;
            }),
        );
        this.subscriptions.add(
            combineLatest([
                this.heatPumpStateService.manufacturerSelection$,
                this.heatPumpStateService.compatibleOutdoorUnitIds$,
                this.heatPumpStateService.categorySelection$
            ]).pipe(
                distinctUntilChanged(([prevManufacturer, prevOutdoorUnitIds, prevCategory], [currManufacturer, currOutdoorUnitIds, currCategory]) =>
                    prevManufacturer === currManufacturer &&
                    prevOutdoorUnitIds === currOutdoorUnitIds &&
                    prevCategory === currCategory
                ),
                filter(([manufacturerSelection, compatibleOutdoorUnitIds]) =>
                    !!manufacturerSelection && compatibleOutdoorUnitIds.length > 0
                ),
                switchMap(([manufacturerSelection, compatibleOutdoorUnitIds, categoryId]) => {
                    this.manufacturerSelection = manufacturerSelection;
                    this.compatibleOutdoorUnitIds = compatibleOutdoorUnitIds;
                    this.categoryId = categoryId;
    
                    if (this.manufacturerSelection.RequiresChange) {
                        this.selectedIndoorHeatPumps = [];
                        this.heatPumpStateService.proposalIndoorHeatPumps$.next([]);
                    }
    
                    return this.partService.getAvailableIndoorUnitsByManufacturerAndOutdoorUnits(
                        this.proposalId,
                        this.manufacturerSelection.ManufacturerId,
                        this.compatibleOutdoorUnitIds,
                        this.categoryId
                    );
                })
            ).subscribe((parts) => {
                this.parts = parts.body;
                this.removeUnavailablePartSetups(this.parts, this.selectedIndoorHeatPumps);
            })
        );
    }

    private removeUnavailablePartSetups(parts: IPart[], selectedIndoorHeatPumps: IIndoorHeatPumpLineItem[]): void {
        const firstCompatiblePart = parts[0];
        if (!firstCompatiblePart) {
            this.selectedIndoorHeatPumps = [];
            this.notificationsService.error('No compatible indoor units found');
        } else {
            const partIds = parts.map((p) => p.Id);
            this.selectedIndoorHeatPumps = selectedIndoorHeatPumps.map((selectedPump) => {
                if (partIds.includes(selectedPump.PartId)) return selectedPump;
                return {
                    BTUCapacity: firstCompatiblePart.BtuCapacity * selectedPump.Quantity,
                    C3InchLineHide: 0,
                    C4InchLineHide: 0,
                    DrainLine: selectedPump.DrainLine,
                    DrainLineId: selectedPump.DrainLineId,
                    Id: selectedPump.Id,
                    LineSet: selectedPump.LineSet,
                    LineSetPorts: this.proposalIndoorHeatPumpService.buildLineSetPortLabel(firstCompatiblePart),
                    PartId: firstCompatiblePart.Id,
                    Quantity: selectedPump.Quantity,
                    RoomId: selectedPump.RoomId,
                    UnitType: firstCompatiblePart['IndoorUnitType']?.Name,
                    ProposalIndoorHeatPumpLineHides: [
                        {
                            Id: selectedPump.ProposalIndoorHeatPumpLineHides.length > 0 ? selectedPump.ProposalIndoorHeatPumpLineHides[0].Id : 0,
                            LineHideTypeId:
                                selectedPump.ProposalIndoorHeatPumpLineHides.length > 0
                                    ? selectedPump.ProposalIndoorHeatPumpLineHides[0].LineHideTypeId
                                    : 1,
                            LineHideValue:
                                selectedPump.ProposalIndoorHeatPumpLineHides.length > 0
                                    ? selectedPump.ProposalIndoorHeatPumpLineHides[0].LineHideValue
                                    : 0,
                        },
                    ],
                };
            });
        }

        this.updateIndoorUnitQuantity();
        this.updateBTUTotal();
        this.updateHeatPumpConfigurationStatus();
        this.updateIndoorHeatPumps();
    }

    private mapIndoorPumps(pumps: IProposalIndoorHeatPump[]): void {
        // BUG: The property name is being generated incorrectly by the model generator
        // i.e. should be 'IndoorUnitType' not 'IndoorUnit'
        this.selectedIndoorHeatPumps = pumps.map((p) => {
            return {
                BTUCapacity: p.Part.BtuCapacity * p.Quantity,
                C3InchLineHide: 0,
                C4InchLineHide: 0,

                DrainLine: p.DrainLine,
                DrainLineId: p.DrainLineId,
                Id: p.Id,
                LineSet: p.LineSet,
                LineSetPorts: this.proposalIndoorHeatPumpService.buildLineSetPortLabel(p.Part),
                PartId: p.PartId,
                Quantity: p.Quantity,
                RoomId: p.RoomId,
                UnitType: p.Part['IndoorUnitType']?.Name,
                ProposalIndoorHeatPumpLineHides: [
                    {
                        Id: p.ProposalIndoorHeatPumpLineHides.length > 0 ? p.ProposalIndoorHeatPumpLineHides[0].Id : 0,
                        LineHideTypeId: p.ProposalIndoorHeatPumpLineHides.length > 0 ? p.ProposalIndoorHeatPumpLineHides[0].LineHideTypeId : 1,
                        LineHideValue: p.ProposalIndoorHeatPumpLineHides.length > 0 ? p.ProposalIndoorHeatPumpLineHides[0].LineHideValue : 0,
                    },
                ],
            };
        });
        this.updateIndoorUnitQuantity();
        this.updateLineSet();
        this.updateDrainLine();
        this.updateBTUTotal();
        this.updateHeatPumpConfigurationStatus();
        this.updateIndoorHeatPumps();

        // Use JSON.parse to save by value instead of reference https://stackoverflow.com/a/18359187.
        this.selectedIndoorHeatPumpsOnInit = JSON.parse(JSON.stringify(this.selectedIndoorHeatPumps));
    }

    addIndoorHeatPump(): void {
        this.selectedIndoorHeatPumps.push({
            BTUCapacity: 0,
            C3InchLineHide: 0,
            C4InchLineHide: 0,
            DrainLine: 0,
            DrainLineId: 0,
            Id: 0,
            LineSet: 0,
            LineSetPorts: '',
            PartId: null,
            Quantity: 0,
            RoomId: null,
            UnitType: '',
            ProposalIndoorHeatPumpLineHides: [
                {
                    Id: 0,
                    LineHideTypeId: 1,
                    LineHideValue: 0,
                },
            ],
        });
    }

    onPumpsUpdate(updatedValue: IIndoorHeatPumpLineItemUpdateEvent): void {
        this.selectedIndoorHeatPumps = updatedValue.SelectedPumps;
        this.updateIndoorUnitQuantity();
        this.updateBTUTotal();
        this.updateLineSet();
        this.updateDrainLine();
        this.updateHeatPumpConfigurationStatus();
        this.updateIndoorHeatPumps();
        if (updatedValue.RequiresBranchBoxPrompt) {
            this.validateBranchBoxPrompt();
        }
        if (!updatedValue.SelectedPumps) {
            return;
        }
        const formIsDirty = this.checkIfFormIsDirty(updatedValue.SelectedPumps, this.selectedIndoorHeatPumpsOnInit);
        this.customerProposalService.setFormIsDirty(formIsDirty);
    }

    private updateIndoorUnitQuantity(): void {
        this.heatPumpConfigurationStatus.Quantity = this.getTotalValue('Quantity');
    }

    private updateBTUTotal(): void {
        this.heatPumpConfigurationStatus.BTUTotal = this.getTotalValue('BTUCapacity');
    }

    private updateLineSet(): void {
        this.heatPumpConfigurationStatus.LineSet = this.getTotalValue('LineSet');
    }

    private updateDrainLine(): void {
        this.heatPumpConfigurationStatus.DrainLine = this.getTotalValue('DrainLine');
    }

    private updateIndoorHeatPumps(): void {
        const pumps: IProposalIndoorHeatPump[] = this.selectedIndoorHeatPumps.map((p) => {
            return {
                C3InchLineHide: p.C3InchLineHide,
                C4InchLineHide: p.C4InchLineHide,
                DrainLine: p.DrainLine,
                DrainLineId: p.DrainLineId,
                Id: p.Id,
                LineSet: p.LineSet,
                PartId: p.PartId,
                ProposalId: this.customerProposalId,
                Quantity: p.Quantity,
                RoomId: p.RoomId,
                ProposalIndoorHeatPumpLineHides: [
                    {
                        Id: p.ProposalIndoorHeatPumpLineHides.length > 0 ? p.ProposalIndoorHeatPumpLineHides[0].LineHideTypeId : 1,
                        LineHideTypeId: +p.ProposalIndoorHeatPumpLineHides[0].LineHideTypeId ?? 1,
                        LineHideValue: +p.ProposalIndoorHeatPumpLineHides[0].LineHideValue ?? 0,
                        ProposalIndoorHeatPumpId: p.Id,
                    },
                ],
            };
        });
        this.heatPumpStateService.proposalIndoorHeatPumps$.next(pumps);
    }

    getTotalValue(key: string): number {
        let value = 0;
        this.selectedIndoorHeatPumps.map((p) => {
            value += p[key];
        });
        return value;
    }

    private checkIfFormIsDirty(proposedPumps: IIndoorHeatPumpLineItem[], currentPumps: IIndoorHeatPumpLineItem[]): boolean {
        if (currentPumps.length !== proposedPumps.length) {
            return true;
        }
        for (let i = 0; i < currentPumps.length; i++) {
            const currentPump = currentPumps[i];
            const proposedPump = proposedPumps[i];
            const pumpsAreDifferent: boolean = this.checkPumpsForDifferences(proposedPump, currentPump);
            if (pumpsAreDifferent) {
                return true;
            }
        }
        return false;
    }

    private checkPumpsForDifferences(proposedPump: IIndoorHeatPumpLineItem, currentPump: IIndoorHeatPumpLineItem): boolean {
        for (const key in currentPump) {
            if (proposedPump.hasOwnProperty.call(currentPump, key)) {
                const proposedPumpField = proposedPump[key];
                const currentPumpField = currentPump[key];
                if (proposedPumpField !== currentPumpField) {
                    return true;
                }
            }
        }
        return false;
    }
}
