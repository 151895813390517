import { Component, OnInit } from '@angular/core';
import { ClaimTypes } from '@model/ClaimTypes';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { ManufacturerDynamicControlsPartial } from '@model/partials/manufacturer.form-controls';
import { CategoryDynamicControls } from '@model/form-controls/category.form-controls';
import { ManufacturerService } from '../services/manufacturer.service';
import { RoomDynamicControlsPartial } from '@model/partials/room.form-controls';
import { RoomService } from '../services/room.service';
import { IManufacturer } from '@model/interfaces/manufacturer';
import { NumericControlTypes } from '@mt-ng2/numeric-control';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { AdminSettingService } from '../services/admin-setting.service';
import { forkJoin } from 'rxjs';
import { IAdminSetting } from '@model/interfaces/admin-setting';
import { Manufacturers } from '@model/Manufacturers';
import { AdminSettings } from '@model/AdminSettings';
import { ModalService } from '@mt-ng2/modal-module';
import { inject } from '@angular/core';
import { CategoryService } from '../services/category.service';
import { ICategory } from '@model/interfaces/category';
import { IWysiwygComponentConfig } from '@mt-ng2/wysiwyg-control';
@Component({
    selector: 'estimator-settings',
    templateUrl: 'estimator-settings.component.html',
})
export class EstimatorSettingsComponent implements OnInit {
    categoryService: CategoryService = inject(CategoryService);

    manufacturerDynamicForm = new ManufacturerDynamicControlsPartial().Form;
    categoryDynamicForm = new CategoryDynamicControls().Form;
    roomDynamicForm = new RoomDynamicControlsPartial().Form;
    canAdd: boolean;
    canRemove: boolean;
    canEdit: boolean;
    materialsManufacturer: IManufacturer;
    numericControlTypes = NumericControlTypes;
    adminFee: IAdminSetting;
    boilerplateJobDescription: IAdminSetting;
    mitsubishiManufacturer: IManufacturer;
    categories: ICategory[];
    note: string;

    noteWysiwygConfig: IWysiwygComponentConfig = {
        toolbar: ['link'],
    };
    constructor(
        private claimsService: ClaimsService,
        public manufacturerService: ManufacturerService,
        public roomService: RoomService,
        private notificationsService: NotificationsService,
        private adminSettingService: AdminSettingService,
        private _modalService: ModalService,
    ) {}

    ngOnInit(): void {
        this.canEdit = this.claimsService.hasClaim(ClaimTypes.Estimator_CanUpdateSettings, [ClaimValues.FullAccess]);
        this.canAdd = this.canEdit;
        this.canRemove = this.canEdit;
        forkJoin([
            this.manufacturerService.getById(Manufacturers.Materials),
            this.manufacturerService.getById(Manufacturers.Mitsubishi),
            this.adminSettingService.getAll(),
            this.categoryService.getItems(),
        ]).subscribe(([materialsManufacturer, mitsubishiManufacturer, adminSettings, categories]) => {
            this.materialsManufacturer = materialsManufacturer;
            this.mitsubishiManufacturer = mitsubishiManufacturer;
            this.categories = categories;
            this.adminFee = adminSettings.find((s) => s.Id === AdminSettings.AdminFee);
            this.boilerplateJobDescription = adminSettings.find((s) => s.Id === AdminSettings.BoilerplateJobDescription);
        });
    }

    updateManufacturer(manufacturer: IManufacturer): void {
        this._modalService
            .showModal({
                text: 'On saving, it will update Margin for all the Customer Proposals that are in progress. Are you sure you want to save the changes?',
                title: 'Confirm Save',
            })
            .subscribe((answer) => {
                if (answer.isConfirmed) {
                    this.manufacturerService.updateManufacturer(manufacturer).subscribe(() => {
                        this.notificationsService.success(`Successfully updated ${manufacturer.Name}`);
                    });
                }
            });
    }

    updateAdminSettings(): void {
        this.adminSettingService.updateItems([this.adminFee, this.boilerplateJobDescription]).subscribe(() => {
            this.notificationsService.success('Successfully updated Admin Settings');
        });
    }

    onAdminFeeChange(): void {
        if (!this.adminFee.Value) {
            this.adminFee.Value = null;
        }
    }
}
