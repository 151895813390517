import { CustomerDynamicControls, ICustomerDynamicControlsParameters } from '../form-controls/customer.form-controls';
import { ICustomer } from '../interfaces/customer';
import {
    CustomFormValidation,
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    IDynamicField,
    IDynamicFieldType,
    InputTypes,
    noZeroRequiredValidator,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { ClaimTypes } from '../ClaimTypes';
import { IProposalJobDetailDynamicControlsParameters, ProposalJobDetailDynamicControls } from '../form-controls/proposal-job-detail.form-controls';
import { IProposalJobDetail } from '../interfaces/proposal-job-detail';
import { JobTypes } from '../JobTypes';
import { ValidationErrors, Validators } from '@angular/forms';
import { IProposalJobDetailDynamicControlsParametersPartial } from './proporal-job-detail-dynamic-controls-parameters';

export class ProposalJobDetailDynamicControlsPartial extends ProposalJobDetailDynamicControls {
    constructor(proposalJobDetail: IProposalJobDetail, additionalParameters?: IProposalJobDetailDynamicControlsParametersPartial) {
        super(proposalJobDetail, additionalParameters);

        // Residential jobs can only have one installer, we lock it and override the value to 1 to be safe
        if (proposalJobDetail && proposalJobDetail.JobTypeId === JobTypes.Residential) {
            (this.Form.NoOfInstallers as DynamicField).disabled = true;
            (this.Form.NoOfInstallers as DynamicField).value = 1;
        }

        this.liftTypes.sort((a, b) => a.Sequence - b.Sequence);
        this.oduElevations.sort((a, b) => a.Id - b.Id);

        (this.Form.JobTypeId as DynamicField).type.inputType = SelectInputTypes.Dropdown;
        (this.Form.LineHideColorId as DynamicField).type.inputType = SelectInputTypes.Dropdown;
        (this.Form.JobDifficulty as DynamicField).type.inputType = InputTypes.Textbox;
        (this.Form.JobDifficulty as DynamicField).labelHtml = '<label>Estimated Job Days</label>';
        (this.Form.JobDifficulty as DynamicField).validation = [Validators.required];
        (this.Form.JobDifficulty as DynamicField).errorMessageHandler = (key: string, errors: ValidationErrors, dynamicField: any) => {
            return key === 'required' ? 'Estimated Job Days is required' : '';
        };

        (this.Form.FoundationTypeId as DynamicField).type.inputType = SelectInputTypes.Dropdown;
        (this.Form.WallConstructionTypeId as DynamicField).type.inputType = SelectInputTypes.Dropdown;
        (this.Form.LiftTypeId as DynamicField).type.inputType = SelectInputTypes.Dropdown;
        (this.Form.OduElevationId as DynamicField).type.inputType = SelectInputTypes.Dropdown;
        (this.Form.OduElevationId as DynamicField).options = this.oduElevations;
        (this.Form.LiftTypeId as DynamicField).labelHtml = '<label>Ladder/Lift Type</label>';
        (this.Form.LiftTypeId as DynamicField).options = this.liftTypes;
        (this.Form.NoOfInstallers as DynamicField).validators = { min: 1 };
        (this.Form.NoOfInstallers as DynamicField).validation = [Validators.min(1)];
    }
}
