import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IBtuCalculationLineItem } from '@model/interfaces/custom/btu-calculation-line-item';
import { IRoom } from '@model/interfaces/room';
import { InsulationFactors } from '@model/InsulationFactors';
import { RoomService } from 'admin-portal/estimator/services/room.service';
@Component({
    selector: '[btu-calculation-line-item]',
    templateUrl: 'btu-calculation-line-item.component.html',
    styles: [
        `
            .input-group {
                display: flex;
                position: relative;
            }

            .input-group .form-control {
                flex: 1;
                border-radius: 0.375rem 0 0 0.375rem;
            }

            .input-group-append .btn {
                border-radius: 0 0.375rem 0.375rem 0;
                margin-left: -1px;
                padding: 0.375rem 0.75rem;
                height: 100%;
            }
        `,
    ],
})
export class BtuCalculationLineItemComponent {
    @Input() btuCalculations: IBtuCalculationLineItem[];
    @Input() index: number;
    @Input() rooms: IRoom[];
    @Input() proposalIsApproved: boolean;
    @Output() onBtuCalculationsUpdate: EventEmitter<IBtuCalculationLineItem[]> = new EventEmitter<IBtuCalculationLineItem[]>();
    @Output() roomAdded: EventEmitter<IRoom> = new EventEmitter<IRoom>();
    insulationFactors = [InsulationFactors.Tight, InsulationFactors.Moderate, InsulationFactors.Average, InsulationFactors.Poor];
    showNewRoomInput = false;
    newRoomName = '';

    constructor(private roomService: RoomService) {}

    onFieldUpdate(index: number): void {
        this.updateCalculationSquareFootage(index);
        this.updateCalculationRoomTotal(index);
        this.onBtuCalculationsUpdate.emit(this.btuCalculations);
    }

    addNewRoom(index: number): void {
        if (this.newRoomName.trim()) {
            const newRoom: IRoom = { Id: 0, Name: this.newRoomName.trim(), Archived: false };

            this.roomService.addRoom(newRoom).subscribe((addedRoom: IRoom) => {
                this.rooms.push(addedRoom);
                this.onBtuCalculationsUpdate.emit(this.btuCalculations);
                this.newRoomName = '';
                this.roomAdded.emit(addedRoom);
                alert('Room added successfully');
            });
        }
    }

    updateCalculationRoomTotal(index: number): void {
        this.btuCalculations[index].RoomTotal =
            this.btuCalculations[index].Width * this.btuCalculations[index].Length * this.btuCalculations[index].InsulationFactor;
    }

    get isAddRoomButtonDisabled(): boolean {
        return !this.newRoomName.trim() || this.proposalIsApproved;
    }

    private updateCalculationSquareFootage(index: number): void {
        this.btuCalculations[index].SquareFootage = this.btuCalculations[index].Width * this.btuCalculations[index].Length;
    }

    removeBtuCalculation(index: number): void {
        this.btuCalculations.splice(index, 1);
        this.onBtuCalculationsUpdate.emit(this.btuCalculations);
    }
}
