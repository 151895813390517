import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MetaItemService } from '@mt-ng2/base-service';
import { Observable } from 'rxjs';
import { IOfficeFee } from '../../../model/interfaces/office-fee';
import { IOffice } from '../../../model/interfaces/office';

@Injectable({
    providedIn: 'root',
})
export class OfficeFeeService extends MetaItemService<IOffice> {
    constructor(public http: HttpClient) {
        super('OfficeFeeService', 'OfficeFee', 'OfficeFeeIds', '/office/fees', http);
    }
}
