import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin, Subscription } from 'rxjs';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { CustomerProposalInterfaceStateService } from '../services/customer-proposal-interface-state.service';
import { CustomerProposalService } from '../services/customer-proposal.service';
import { ProposalFormSteps } from '@model/ProposalFormSteps';
import { JobTypes } from '@model/JobTypes';
import { ProposalJobDetailDynamicControlsPartial } from '../../../model/partials/proposal-job-detail.form-controls';
import { JobTypeService } from '../services/job-type.service';
import { LineHideColorService } from '../services/line-hide-color.service';
import { FoundationTypeService } from '../services/foundation-type.service';
import { WallConstructionTypeService } from '../services/wall-construction-type.service';
import { LiftTypeService } from '../services/lift-type.service';
import { OduElevationService } from '../services/odu-elevation.service';
import { ICustomerProposal } from '@model/interfaces/customer-proposal';
import { IProposalJobDetail } from '@model/interfaces/proposal-job-detail';
import { ProposalJobDetailService } from '../services/proposal-job-detail.service';
import { LineHideColors } from '@model/LineHideColors';
import { IExpandableObject } from '@model/expandable-object';

@Component({
    selector: 'proposal-job-details-form',
    styles: [`
        .form-checkbox {
            margin-left: 10px;
        }
    `],
    templateUrl: './proposal-job-details-form.component.html',
})
export class ProposalJobDetailsFormComponent implements OnInit, OnDestroy {

    // abstract controls
    abstractProposalJobDetailControls: IExpandableObject;

    proposalJobDetailsForm: UntypedFormGroup;
    doubleClickIsDisabled = false;
    formCreated = false;
    subscriptions: Subscription = new Subscription();
    customerProposal: ICustomerProposal;
    jobDetail: IProposalJobDetail;

    ProposalFormSteps = ProposalFormSteps;
    proposalIsApproved = false;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private fb: UntypedFormBuilder,
        private cdr: ChangeDetectorRef,
        private notificationsService: NotificationsService,
        private jobTypeService: JobTypeService,
        private lineHideColorService: LineHideColorService,
        private foundationTypeService: FoundationTypeService,
        private wallConstructionTypeService: WallConstructionTypeService,
        private liftTypeService: LiftTypeService,
        private oduElevationService: OduElevationService,
        private customerProposalService: CustomerProposalService,
        private stateService: CustomerProposalInterfaceStateService,
        private proposalJobDetailService: ProposalJobDetailService,
    ) { }

    ngOnInit(): void {
        forkJoin([
            this.jobTypeService.getItems(),
            this.lineHideColorService.getItems(),
            this.foundationTypeService.getItems(),
            this.wallConstructionTypeService.getItems(),
            this.liftTypeService.getItems(),
            this.oduElevationService.getItems(),
        ]).subscribe((answer) => {
            this.createForm();
            this.initializeCustomerProposalSubscription();
            this.jobDetail = this.jobDetail ?? this.proposalJobDetailsForm.value.ProposalJobDetail;
            this.proposalJobDetailsForm.valueChanges.subscribe((value) => {
                const formIsDirty = this.checkIfFormIsDirty(this.jobDetail, value.ProposalJobDetail as IProposalJobDetail);
                this.customerProposalService.setFormIsDirty(formIsDirty);
            });
        });
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    initializeCustomerProposalSubscription(): void {
        this.subscriptions.add(
            this.stateService.customerProposal$.subscribe((value) => {
                this.customerProposal = value;
                // Lock a Proposal after approval
                if (!this.customerProposalService.proposalIsInProgress(this.customerProposal)) {
                    this.proposalJobDetailsForm.disable();
                    this.proposalIsApproved = true;
                }
            }),
        );
    }

    createForm(): void {
        this.getControls();
        this.proposalJobDetailsForm = this.assignFormGroups();
        this.formCreated = true;
        this.cdr.detectChanges();
    }

    getControls(): void {
        this.customerProposal = this.stateService.customerProposal$.value;
        this.jobDetail = this.customerProposal && this.customerProposal.ProposalJobDetails && this.customerProposal.ProposalJobDetails.length ? this.customerProposal.ProposalJobDetails[0] : null;
        
        this.abstractProposalJobDetailControls = new ProposalJobDetailDynamicControlsPartial(
            this.jobDetail,
            {
                formGroup: 'ProposalJobDetail',
                foundationTypes: this.foundationTypeService.items,
                jobTypes: this.jobTypeService.items,
                liftTypes: this.liftTypeService.items,
                lineHideColors: this.lineHideColorService.items.filter((lhc) => lhc.Id !== LineHideColors.Gray),
                oduElevations: this.oduElevationService.items,
                proposals: [],
                wallConstructionTypes: this.wallConstructionTypeService.items,
            },
        ).Form;
    }

    assignFormGroups(): UntypedFormGroup {
        return this.fb.group({
            ProposalJobDetail: this.fb.group({}),
        });
    }

    jobTypeChanged(event: number): void {
        if (event === JobTypes.Residential) {
            (<UntypedFormGroup>this.proposalJobDetailsForm.controls.ProposalJobDetail).controls.NoOfInstallers.setValue(1);
            (<UntypedFormGroup>this.proposalJobDetailsForm.controls.ProposalJobDetail).controls.NoOfInstallers.disable();
        } else {
            (<UntypedFormGroup>this.proposalJobDetailsForm.controls.ProposalJobDetail).controls.NoOfInstallers.enable();
        }
    }

    formSubmitted(): void {
        if (this.proposalJobDetailsForm.valid) {
            const saveJobDetail: IProposalJobDetail = { ...this.jobDetail };
            this.assignFormValues(saveJobDetail, this.proposalJobDetailsForm.value.ProposalJobDetail as IProposalJobDetail);
            saveJobDetail.ProposalId = this.customerProposal.Id;
            if (saveJobDetail.JobTypeId === JobTypes.Residential) {
                saveJobDetail.NoOfInstallers = 1;
            }
            this.saveJobDetails(saveJobDetail);
        } else {
            markAllFormFieldsAsTouched(this.proposalJobDetailsForm);
            this.error();
        }    
    }

    assignFormValues(jobDetail: IProposalJobDetail, currentFormValue: IProposalJobDetail): void {
        for (const key in currentFormValue) {
            if (jobDetail.hasOwnProperty.call(currentFormValue, key)) {
                jobDetail[key] = currentFormValue[key];
            }
        }
    }

    saveJobDetails(saveJobDetail: IProposalJobDetail): void {
        if (!this.jobDetail || !this.jobDetail.Id) {
            this.proposalJobDetailService.create(saveJobDetail)                .subscribe((answer) => {
                    saveJobDetail.Id = answer;
                    this.stateService.markStepCompleted(ProposalFormSteps.JobDetails);
                    this.customerProposalService.setFormIsDirty(false);
                    this.success();
                });
        } else {
            this.proposalJobDetailService.update(saveJobDetail)                .subscribe(() => {
                    this.success();
                });
        }
    }

    cancelClick(): void {
        void this.router.navigate(['../'], { relativeTo: this.route });
    }

    error(): void {
        this.notificationsService.error('Save failed.  Please check the form and try again.');
    }

    success(): void {
        this.customerProposalService.getById(this.customerProposal.Id).subscribe((cp) => {
            this.stateService.customerProposal$.next(cp);
        });
        this.notificationsService.success('Job Details saved successfully.');
        this.updateCurrentFormStep(ProposalFormSteps.BasicInfo);
    }

    updateCurrentFormStep(step: ProposalFormSteps): void {
        this.stateService.currentFormStep$.next(step);
    }

    private checkIfFormIsDirty(jobDetails: IProposalJobDetail, currentFormValues: IProposalJobDetail): boolean {
        for (const key in currentFormValues) {
            if (jobDetails.hasOwnProperty.call(currentFormValues, key)) {
                const jobDetail = jobDetails[key];
                const currentFormValue = currentFormValues[key];
                if (jobDetail !== currentFormValue) {
                    return true;
                }
            }
        }
        return false;
    }
}
