<div>
    <div class="row">
        <div class="col-md-7">
            <h2>BTU Calculations</h2>
        </div>
    </div>
</div>

<div class="miles-card padded">
    <div class="row">
        <div class="col-md-8">
            <h3>Simple Heat Loss Calculator</h3>
            <p>
                This heat loss calculator is designed to roughly determine how many BTUs per hour you and your space need. The chart below shows
                measurements taken for the rooms you have specified, along with the insulation value you have indicated.
            </p>
            <p>We offer heat pumps that operate at -5, 5, and 17 degrees. Hyper Heat Pumps will operate down to -13 degrees.</p>
            <p>
                The amount of BTUs a heat pump can generate changes with outside temperature. Example: An MUZFS15NAU1 Mitsubishi Hyper pump puts out
                14,400 BTUs at -13 degrees, 16,000 at -5, 19,360 at 5 degrees and 22,730 at 17 degrees.
            </p>
        </div>
    </div>
    <div class="row">
        <div class="col-md-8">
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th>Room</th>
                        <th>Length</th>
                        <th>Width</th>
                        <th>Square Footage</th>
                        <th>Insulation Factor</th>
                        <th>Room Total</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngIf="btuCalculations.length === 0">
                        <td colspan="7">
                            <i>No BTU Calculations configured</i>
                        </td>
                    </tr>
                    <tr
                        btu-calculation-line-item
                        *ngFor="let calculation of btuCalculations; index as i"
                        [index]="i"
                        [btuCalculations]="btuCalculations"
                        [rooms]="rooms"
                        (onBtuCalculationsUpdate)="onCalculationsUpdate($event)"
                        (roomAdded)="onRoomAdded($event)"
                        [proposalIsApproved]="proposalIsApproved"
                    ></tr>
                </tbody>
                <thead>
                    <tr>
                        <th colspan="4"></th>
                        <th>House Total</th>
                        <th>{{ houseTotal }}</th>
                        <th></th>
                    </tr>
                </thead>
            </table>
            <div class="btn btn-primary btn-fab-md" (click)="addBtuCalculation()" *ngIf="!proposalIsApproved">
                <span class="fa fa-plus"></span>
            </div>
        </div>
        <div class="col-md-4">
            <div class="form-group">
                <label>Heat Loss Notes</label>
                <textarea class="form-control" rows="5" [(ngModel)]="customerProposal.HeatLossNotes" (blur)="onHeatLossNotesUpdate()"></textarea>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-3 col-md-offset-9">
            <div>
                <button class="btn btn-flat btn-primary" [disabled]="proposalIsApproved" (click)="saveBtuCalculations()">Save</button>
                <button class="btn btn-flat btn-default" (click)="updateCurrentFormStep(ProposalFormSteps.BasicInfo)">Cancel</button>
                <button
                    class="btn btn-flat btn-default"
                    [disabled]="!isCompletedFormStep(ProposalFormSteps.BtuCalculations)"
                    (click)="downloadBtuCalculationsPdf()"
                >
                    Download PDF
                </button>
            </div>
        </div>
    </div>
</div>
