
<label>Indoor Heat Pump(s)</label>
<div class="table-responsive">
    <table class="table table-bordered">
        <thead>
            <tr>
                <th class="th-name-input">Model name</th>
                <th>Location</th>
                <th>Quantity</th>
                <th class="th-numeric-input">Line Set</th>
                <th>Line Hide</th>
                <th>Drain Line</th>
                <th>Unit Type</th>
                <th>Line Set Ports</th>
                <th>Image</th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngIf="selectedIndoorHeatPumps.length === 0">
                <td colspan="9">
                    <i>No selected Indoor Heat pumps</i>
                </td>
            </tr>
            <tr
                indoor-heat-pump-line-item
                *ngFor="let pump of selectedIndoorHeatPumps; index as i"
                [index]="i"
                [selectedIndoorHeatPumps]="selectedIndoorHeatPumps"
                [rooms]="rooms"
                [parts]="parts"
                [lineHideTypes]="lineHideTypes"
                (onSelectedHeatPumpsUpdate)="onPumpsUpdate($event)"
                [proposalIsApproved]="proposalIsApproved"
            ></tr>
        </tbody>
    </table>
</div>

<div class="btn btn-primary btn-fab-md" (click)="addIndoorHeatPump()" *ngIf="!proposalIsApproved">
    <span class="fa fa-plus"></span>
</div>
