<th>
    <select
        *ngIf="parts.length > 0"
        class="form-control"
        id="part"
        [(ngModel)]="selectedIndoorHeatPumps[index].PartId"
        (ngModelChange)="onPartUpdate($event, index)"
        [ngModelOptions]="{ standalone: true }"
        [disabled]="proposalIsApproved"
    >
        <option *ngFor="let p of parts" [value]="p.Id">{{ p.Name }}</option>
    </select>
</th>
<th>
    <select
        *ngIf="rooms.length > 0"
        class="form-control"
        id="room"
        [(ngModel)]="selectedIndoorHeatPumps[index].RoomId"
        (ngModelChange)="onRoomUpdate()"
        [ngModelOptions]="{ standalone: true }"
        [disabled]="proposalIsApproved"
    >
        <option *ngFor="let r of rooms" [value]="r.Id">{{ r.Name }}</option>
    </select>
</th>
<th>
    <span (click)="adjustPartQuantity(1, index)" *ngIf="!proposalIsApproved">
        <i class="fa fa-lg fa-plus-circle"></i>
    </span>
    <span>{{ selectedIndoorHeatPumps[index].Quantity }}</span>
    <span *ngIf="selectedIndoorHeatPumps[index].Quantity > 0 && !proposalIsApproved" (click)="adjustPartQuantity(-1, index)">
        <i class="fa fa-lg fa-minus-circle"></i>
    </span>
</th>
<th>
    <input
        type="number"
        class="form-control small-input"
        id="lineset"
        min="0"
        [(ngModel)]="selectedIndoorHeatPumps[index].LineSet"
        (ngModelChange)="onFieldUpdate(index, 'LineSet')"
        [ngModelOptions]="{ standalone: true }"
        [disabled]="proposalIsApproved"
        [ngClass]="
            selectedIndoorHeatPumps[index].PartId > 0 && selectedIndoorHeatPumps[index].LineSet <= 0
                ? 'customerProposalForm__numberSelect--error'
                : ''
        "
    />
</th>
<th>
    <select
        class="form-control"
        [(ngModel)]="selectedIndoorHeatPumps[index].ProposalIndoorHeatPumpLineHides[0].LineHideTypeId"
        (ngModelChange)="onFieldUpdate(index, 'LineHideTypeId')"
    >
        <option *ngFor="let lineHideType of lineHideTypes" [value]="lineHideType.Id">
            {{ lineHideType.Name }}
        </option>
    </select>
    <input
        type="number"
        class="form-control small-input"
        id="lineHideValue"
        min="0"
        [(ngModel)]="selectedIndoorHeatPumps[index].ProposalIndoorHeatPumpLineHides[0].LineHideValue"
        (ngModelChange)="onFieldUpdate(index, 'LineHideValue')"
        [ngModelOptions]="{ standalone: true }"
        [disabled]="proposalIsApproved"
    />
</th>
<th>
    <select class="form-control" [(ngModel)]="selectedIndoorHeatPumps[index].DrainLineId" (ngModelChange)="onFieldUpdate(index, 'DrainLineId')">
        <option *ngFor="let drainLineType of drainLines" [value]="drainLineType.Id">
            {{ drainLineType.Name }}
        </option>
    </select>
    <input
        type="number"
        class="form-control small-input"
        id="drainline"
        min="0"
        [(ngModel)]="selectedIndoorHeatPumps[index].DrainLine"
        (ngModelChange)="onFieldUpdate(index, 'DrainLine')"
        [ngModelOptions]="{ standalone: true }"
        [disabled]="proposalIsApproved"
        [ngClass]="
            selectedIndoorHeatPumps[index].PartId > 0 && selectedIndoorHeatPumps[index].DrainLine <= 0
                ? 'customerProposalForm__numberSelect--error'
                : ''
        "
    />
</th>
<th>
    {{ selectedIndoorHeatPumps[index].UnitType }}
</th>
<th>
    {{ selectedIndoorHeatPumps[index].LineSetPorts }}
</th>
<th class="icon-center">
    <span *ngIf="heatPumpImgPath; else noImage">
        <i class="fa fa-check check"></i>
    </span>
    <ng-template #noImage>
        <i class="fa fa-times cross"></i>
    </ng-template>
</th>
<th>
    <button class="btn btn-default" [disabled]="proposalIsApproved" (click)="removeIndoorHeatPump(index)">Remove</button>
</th>
